<template>
    <div>
        <a-modal title="导入" :visible="dialog" :footer="null" @cancel="dialog=false">
        <span>
            <input ref="upload" style="display: none" type="file" @change="onUpload" />

            <a-button :loading="loading" @click="onClick" style="margin-right: 10px;"
            >导入</a-button>
            <a href="javacript:void(0)" @click="download">下载模板</a>
        </span>
        </a-modal>
        <Pane />
        <a-card class="container">
            <a-row style="margin-bottom: 10px;">
                <a-col :span="22">
                <a-form-model layout="inline">
                    <a-form-model-item>
                        <a-input v-model="form.userName" placeholder="姓名" style="width: 160px"></a-input>
                    </a-form-model-item>
                    <a-form-model-item>
                    <a-select
                        placeholder="部门"
                        v-model="form.deptId"
                        show-search
                        style="width: 160px"
                        :filter-option="$selectFilterOption"
                    >
                        <a-select-option
                        v-for="item in organizationList"
                        :key="item.id"
                        :value="item.id"
                        >{{ item.uniqueName }}</a-select-option
                        >
                    </a-select>
                    </a-form-model-item>
                    <a-form-model-item>
                        入职时间:
                        <a-range-picker :allowClear="false" v-model="form.date" />
                    </a-form-model-item>
                    <a-form-model-item>
                        <a-space>
                            <a-button @click="query" type="primary">查询</a-button>
                            <a-button @click="reset">重置</a-button>
                        </a-space>
                    </a-form-model-item>
                </a-form-model>
                </a-col>  
                <a-col :span="2">
                    <div class="right" style="margin-top: 4px">
                        <a-button v-if="$getPermission($route.path + '/edit')"  @click="downloadFile" style="margin-right: 10px;"
                        >导出</a-button>
                        <a-button v-if="$getPermission($route.path + '/edit')"  @click="dialog=true" style="margin-right: 10px;"
                        >导入</a-button>
                    </div>
                </a-col>
            </a-row>
            <a-table bordered :dataSource="tableList" :loading="loading" @change="onChange" :pagination="{
                total,
                current,
                pageSize,
                showTotal: (total) => `共 ${total} 条记录`,
            }" rowKey="id">
                    <a-table-column title="姓名" align="center" width="100px">
                        <template slot-scope="text">
                            {{ text?.employee?.name }}
                        </template>
                    </a-table-column>
                    <a-table-column title="从事专业" data-index="professional" align="center"></a-table-column>
                    <a-table-column title="部门" data-index="employee.deptName" align="center" width="150px"></a-table-column>
                    <a-table-column title="行政职务" align="center">
                        <template slot-scope="text">
                            <a-select class="selectNoBorder" :disabled="true" show-search
                                :filter-option="$selectFilterOption" size="small" placeholder="请选择" v-model="text.administrativePosition">
                                <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{
                                item.name }}</a-select-option>
                            </a-select>
                        </template>
                    </a-table-column>
                    <a-table-column title="技术职务" align="center">
                        <template slot-scope="text">
                            <a-select class="selectNoBorder" :disabled="true" show-search
                                :filter-option="$selectFilterOption" size="small" placeholder="请选择" v-model="text.technicalPosition">
                                <a-select-option v-for="item in positionList" :key="item.value" :value="item.value">{{
                                item.name }}</a-select-option>
                            </a-select>
                        </template>
                    </a-table-column>
                    <a-table-column title="职称" align="center">
                        <template slot-scope="text">
                            <DataDictFinder dictType="talentMap.professionalTitle" :dictValue="text?.employee?.professionalTitle" />
                        </template>
                    </a-table-column>
                    <a-table-column title="性别" align="center">
                        <template slot-scope="text">
                            <DataDictFinder dictType="sex" :dictValue="text?.employee?.sex" />
                        </template>
                    </a-table-column>
                    
                    <a-table-column title="最高学历" align="center">
                        <template slot-scope="text">
                            <DataDictFinder dictType="degree" :dictValue="text?.employee?.degree" />
                        </template>
                    </a-table-column>

                    <a-table-column title="入职时间" data-index="employee.entryDate" align="center"></a-table-column>
                    <a-table-column title="参加工作时间" data-index="employee.workStartTime" align="center"></a-table-column>
                    <a-table-column title="工龄" data-index="employee.socialAge" align="center"></a-table-column>
                    <a-table-column title="现职称评审时间" data-index="employee.professionalDate" align="center"></a-table-column>
                    <a-table-column title="执业注册" align="center">
                        <template slot-scope="text">
                            <DataDictFinder dictType="talentMap.registeredProfessionalQualifications" :dictValue="text?.employee?.registeredProfessionalQualifications" />
                        </template>
                    </a-table-column>
                    <a-table-column-group title="任职资格">
                        <a-table-column title="设计" align="center">
                            <template slot-scope="text">
                                <span v-if="text.design">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column title="校核" align="center">
                            <template slot-scope="text">
                                <span v-if="text.check">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column title="专业负责" align="center">
                            <template slot-scope="text">
                                <span v-if="text.professionalResponsibility">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column-group title="项目负责">
                            <a-table-column title="公司管" align="center">
                                <template slot-scope="text">
                                    <span v-if="text.prjComp">√</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="部门管" align="center">
                                <template slot-scope="text">
                                    <span v-if="text.prjDept">√</span>
                                </template>
                            </a-table-column>
                        </a-table-column-group>
                        <a-table-column title="审核" align="center">
                            <template slot-scope="text">
                                <span v-if="text.examine">√</span>
                            </template>
                        </a-table-column>
                        <a-table-column-group title="审定">
                            <a-table-column title="公司管" align="center">
                                <template slot-scope="text">
                                    <span v-if="text.approvedComp">√</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="部门管" align="center">
                                <template slot-scope="text">
                                    <span v-if="text.approvedDept">√</span>
                                </template>
                            </a-table-column>
                        </a-table-column-group>
                        <a-table-column-group title="批准">
                            <a-table-column title="公司管" align="center">
                                <template slot-scope="text">
                                    <span v-if="text.approvalComp">√</span>
                                </template>
                            </a-table-column>
                            <a-table-column title="部门管" align="center">
                                <template slot-scope="text">
                                    <span v-if="text.approvalDept">√</span>
                                </template>
                            </a-table-column>
                        </a-table-column-group>
                        <a-table-column title="内审员" align="center">
                            <template slot-scope="text">
                                <span v-if="text.internalAuditor">√</span>
                            </template>
                        </a-table-column>
                    </a-table-column-group>
                    <a-table-column title="备注" data-index="remark" align="center"></a-table-column>
                    <a-table-column title="操作" align="center" width="70px">
                        <template slot-scope="text">
                            <a-space>
                                <a
                                    href="#"
                                    v-if="$getPermission($route.path + '/edit')"
                                    @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
                                    >编辑</a
                                >
                            </a-space>
                        </template>
                    </a-table-column>
            </a-table>
        </a-card>
    </div>
</template>
<script>
import request from "@/api/request";
import { mapGetters } from "vuex";
import download from '@/api/download'
import { saveAs } from "file-saver";
function importChangeList(data) {
    return request({
        url: "project-service/dsEmployee/import",
        method: 'post',
        data
    });
}
function getTemplate() {
  return download({
    url: "/project-service/dsEmployee/importTemplate",
  });
}
function getFile(data) {
  return download({
    url: "/project-service/dsEmployee/export",
    method: "post",
    data
  });
}
function fetchList(params) {
    return request({
        url: "project-service/dsEmployee/list",
        params: params
    });
}

function fetchNum(year) {
    return request({
        url: "user-service/talentMap/last/" + year,
    });
}

function getOrganization() {
    return request({
        url: "user-service/org/listForPrd",
    });
}
export default {
    props: {
        year: {
            type: Number,
            default: 0
        }
    },
    watch:{
        year(newValue){
            this.now = newValue
            fetchNum(this.now).then(res => {
                this.total = res && res.staffTotal?res.staffTotal:0
            })
        }
    },
    computed: {
        ...mapGetters("setting", ["findDataDict"]),
        ...mapGetters("auth", ["isHr"]),
        sex() {
        return this.findDataDict("sex");
        },
        employeeStatusList() {
        return this.findDataDict("employeeStatus");
        },
        lists() {
        return this.findDataDict("talentMap");
        },
        positionList() {
        return (
            this.lists.find((item) => item.value === "talentMap_post").children || []
        );
        },
    },
    data() {
        return {
            dialog:false,
            form: {
                deptId: ''
            },
            now: 0,
            current: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            total: 0,
            dialogShow: false,
            tableList: [],
            organizationList: []
        };
    },
    mounted() {
        const { query } = this.$route;
        const { id } = query || {};
        this.form.deptId = id
        getOrganization().then(v=>{
            this.organizationList = v
        })
        this.getList()
    },
    methods: {
        downloadFile(){
            let entryDateStart
            let entryDateEnd
            if (Array.isArray(this.form.date) && this.form.date.length === 2) {
                entryDateStart = this.form.date[0].format("YYYY-MM-DD");
                entryDateEnd = this.form.date[1].format("YYYY-MM-DD");
            }
            getFile({entryDateStart:entryDateStart, entryDateEnd:entryDateEnd, userName:this.form.userName,deptId: this.form.deptId,pageNum:this.current,pageSize:this.pageSize}).then(blob=>{
                saveAs(blob, "人力资源表.xlsx");
            })
        },
        download(){
            getTemplate().then(blob=>{
                saveAs(blob, "导入模版.xlsx");
            })
        },
        onClick(){
            this.$refs.upload.click();
        },
        onUpload(e) {
            console.log(e)
            const files = e.target.files;
            const data = new FormData();
            for (let i = 0; i < files.length; i++) {
                data.append("file", files[i]);
            }

            this.loading = true;

            importChangeList(data)
                .then(() => {
                })
                .finally(() => {
                this.loading = false;
                e.target.value = null;
                });
        },
        query() {
            this.current = 1;
            this.getList();
        },
        reset() {
            this.form = {};
            this.getList();
        },
        getList(){
            this.loading = true
            let entryDateStart
            let entryDateEnd
            if (Array.isArray(this.form.date) && this.form.date.length === 2) {
                entryDateStart = this.form.date[0].format("YYYY-MM-DD");
                entryDateEnd = this.form.date[1].format("YYYY-MM-DD");
            }
            fetchList({entryDateStart:entryDateStart, entryDateEnd:entryDateEnd, userName:this.form.userName,deptId: this.form.deptId,pageNum:this.current,pageSize:this.pageSize}).then(res => {
                this.tableList = res.list
                this.total = res.totalSize
                this.dialogShow = true
            }).finally(() => {
                this.loading = false
            })
        },
        onChange(pagination) {
            this.current = pagination.current;
            this.pageSize = pagination.pageSize;
            this.getList()
        },
        showDialog() {
            this.getList();
        }
    }
}
</script>
<style>
.selectNoBorder .ant-select-selection{
  border: none;background: none;color: rgba(0, 0, 0, 0.65);
}
.selectNoBorder .ant-select-arrow{
  display: none;
}
.selectNoBorder .ant-select-selection__rendered{
  margin: 0;
}
</style>